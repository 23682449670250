import {
  DATA_LAYER_SCOPES,
  pushEnrichedEventToDataLayerWithCleanUp
} from "seneca-common/features/analytics";
import {
  EnrichedEventType,
  pushGlobalValuesToDataLayer
} from "seneca-common/features/analytics/gtm/dataLayer";
import { withLogAndSwallowError } from "seneca-common/utils/senecaAnalytics";

import { ProviderSSO } from "../types";
import manageAuthCorrelationId from "./manageAuthCorrelationId";

const authCorrelationId = manageAuthCorrelationId();

const makeEventName = (name: string) => `s/auth/firebase/${name}`;

export function pushProviderToGlobalDataLayer(providerId?: ProviderSSO) {
  pushGlobalValuesToDataLayer({
    [DATA_LAYER_SCOPES.USER]: {
      providerId
    }
  });
}

export function clearProviderFromGlobalDataLayer() {
  pushGlobalValuesToDataLayer({
    [DATA_LAYER_SCOPES.USER]: {
      providerId: undefined
    }
  });
}

export function pushProviderUserIdToGlobalDataLayer(providerUserId?: string) {
  pushGlobalValuesToDataLayer({
    [DATA_LAYER_SCOPES.USER]: {
      providerUserId
    }
  });
}

export function clearProviderUserIdFromGlobalDataLayer() {
  pushGlobalValuesToDataLayer({
    [DATA_LAYER_SCOPES.USER]: {
      providerUserId: undefined
    }
  });
}

export function logAnalyticsAuthStart({
  providerId
}: {
  providerId: ProviderSSO;
}) {
  pushProviderToGlobalDataLayer(providerId);

  const correlationId = authCorrelationId?.create();

  logAnalyticsEvent({
    event: makeEventName("start"),
    payload: { correlationId, providerId }
  });
}

export function logAnalyticsAuthSuccess({
  providerUserId
}: {
  providerId?: ProviderSSO;
  providerUserId?: string;
}) {
  const correlationId = authCorrelationId?.get();
  if (!correlationId) return;

  pushProviderUserIdToGlobalDataLayer(providerUserId);

  logAnalyticsEvent({
    event: makeEventName("success"),
    payload: { correlationId, providerUserId }
  });

  authCorrelationId?.remove();
}

export function logAnalyticsAuthFailure() {
  const correlationId = authCorrelationId?.get();
  if (!correlationId) return;

  logAnalyticsEvent({
    event: makeEventName("failure"),
    payload: { correlationId }
  });

  authCorrelationId?.remove();

  clearProviderFromGlobalDataLayer();
  clearProviderUserIdFromGlobalDataLayer();
}

export function logAnalyticsAuthError({ error }: { error: string }) {
  const correlationId = authCorrelationId?.get();
  if (!correlationId) return;

  logAnalyticsEvent({
    event: makeEventName("error"),
    payload: { correlationId, error }
  });

  authCorrelationId?.remove();

  clearProviderFromGlobalDataLayer();
}

function logAnalyticsEvent(event: EnrichedEventType) {
  return withLogAndSwallowError(() =>
    pushEnrichedEventToDataLayerWithCleanUp(event)
  )();
}
